import Vue from "vue";
import Vuex from "vuex";
import actions from "./store/actions";
import getters from "./store/getters";
import mutations from "./store/mutations";
import createPersistedState from "vuex-persistedstate";
import initState from "./store/initState";
import SecureLS from "secure-ls";
const ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionNamespace: "_er",
  encryptionSecret: "!#$^%^@TEr@k2020erAM",
});

Vue.use(Vuex);
const isProd = process.env.NODE_ENV === "production";
const storage = {
  getItem: (key) => ls.get(key),
  setItem: (key, value) => ls.set(key, value),
  removeItem: (key) => ls.remove(key),
};
const plugins = [
  createPersistedState({
    key: "eram",
    storage: isProd ? null : null,
  }),
];

export default new Vuex.Store({
  state: initState(),
  getters,
  mutations,
  actions,
  plugins,
});
